<div *ngIf="!adeudo" class="container">
    <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
    </div>
    <p class="text-right">Cargando Información...</p>
</div>

<div *ngIf="adeudo">
    <div class="container">
        <div id="informacion">
            <div id="linea"></div>
            <h3 class="text-center p-3">Información de la clave</h3>
            <div id="linea"></div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-4 mb-4 mb-md-0">
                <div class="card p-2" id="contribuyente">
                    <div class="row justify-content-center">
                        <i class="fas fa-user-circle" id="icono"></i>
                    </div>
                    <div class="row justify-content-center pb-2">
                        <h5>{{adeudo.Nombre_Propietario}}</h5>
                    </div>
                    <div class="row justify-content-center">
                        <p><small>{{cve_cast}}</small></p>
                    </div>
                    <div class="row justify-content-center">
                        <p>RFC: <small>{{adeudo.RFC}}</small></p>
                    </div>
                    <div class="row justify-content-center">
                        <p>Domicilio: <small>{{adeudo.Ubicacion_Propiedad}}</small></p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <ul>
                    <li>
                        Domicilio de ubicación: <strong>{{adeudo.Domicilio_Propiedad}}</strong>
                    </li>
                    <li>
                        Colonia de predio: <strong>{{adeudo.Colonia_Propiedad}} </strong>
                    </li>
                    <li>
                        Población: <strong>{{adeudo.Poblacion_Propiedad}}</strong>
                    </li>
                    <li>
                        Municipio: <strong>{{municipio}}</strong>
                    </li>
                </ul>
            </div>
            <div class="col-md-4">
                <ul>
                    <li>
                        Valor castastral: <strong>{{adeudo.Valor_Catastral | currency}}</strong>
                    </li>
                    <li>
                        Superficie de terreno: <strong>{{adeudo.Superficie_Terreno | number: '1.2-2'}}</strong>
                    </li>
                    <li>
                        Superficie de construccion: <strong>{{adeudo.Superficie_Construccion | number:
                            '1.2-2'}}</strong>
                    </li>
                    <li>
                        Subsidio: <strong>{{adeudo.Tipo_Subsidio}}</strong>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div *ngIf="adeudo.Total != 0" class="container d-none d-sm-block" style="margin-top: 1rem;">
        <table class="table">
            <thead>
                <tr class="table-success">
                    <th class="text-center" scope="col">Periodo</th>
                    <th class="text-center" scope="col">Principal</th>
                    <th class="text-center" scope="col">A.C.O</th>
                    <th class="text-center" scope="col">Asistencia Social</th>
                    <th class="text-center" scope="col">Subisidio</th>
                    <th class="text-center" scope="col">Pronto Pago</th>
                    <th class="text-center" scope="col">Recargo</th>
                    <th class="text-center" scope="col">Multa</th>
                    <th class="text-center" scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let detalle of this.adeudo.Detalle; let indice=index;" [ngClass]="{'table-secondary': !detalle.check}">
                    <th>
                        <div class="ml-3">
                            <input [disabled]="indice==0 || recalculando" (click)="CambioCheck(detalle.check, indice)" type="checkbox"
                                checked="detalle.check" [(ngModel)]="detalle.check" class="form-check-input"
                                id="{{ detalle.anio }} - {{ detalle.trimestre }}">
                            <label for="{{ detalle.anio }} - {{ detalle.trimestre }}">{{ detalle.anio }} - {{
                                detalle.trimestre }}</label>
                        </div>
                    </th>
                    <td class="text-right">{{ detalle.principal | currency }}</td>
                    <td class="text-right">{{ detalle.actualizacion | currency }}</td>
                    <td class="text-right">{{ detalle.asistencia_social | currency }}</td>
                    <td class="text-right">{{ detalle.descuento | currency }}</td>
                    <td class="text-right">{{ detalle.prontopago | currency }}</td>
                    <td class="text-right">{{ detalle.recargos | currency }}</td>
                    <td class="text-right">{{ detalle.multas | currency }}</td>
                    <td class="text-right">{{ detalle.total | currency }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="container d-block d-sm-none">
        <div *ngFor="let detalle of this.adeudo.Detalle; let indice=index;" class="row justify-content-center p-2">
            <table class="table table-bordered m-0" [ngClass]="{'table-secondary': !detalle.check}">
                <thead class="table-success" [ngClass]="{'deshabilitado': !detalle.check}">
                    <tr>
                        <th class="p-1">
                            <div class="ml-4">
                                <input [disabled]="indice==0" (click)="CambioCheck(detalle.check, indice)"
                                    type="checkbox" checked="detalle.check" [(ngModel)]="detalle.check"
                                    class="form-check-input" id="{{ detalle.anio }} - {{ detalle.trimestre }}">
                                <label class="m-0" for="{{ detalle.anio }} - {{ detalle.trimestre }}">Periodo:</label>
                            </div>

                        </th>
                        <th class="p-1 text-right">
                            {{ detalle.anio }} - {{ detalle.trimestre }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="p-1">
                            Principal:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.principal | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            A.C.O:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.actualizacion | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Asistencia Social:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.asistencia_social | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Subisidio:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.descuento | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Pronto Pago:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.prontopago | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Recargo:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.recargos | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Multa:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.multas | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Total:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.total | currency }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="this.adeudo.Cve_Catastral == null" class="container d-none d-sm-block" style="margin-top: 1rem;">
        <h1 class="text-center p-5">No existe esta clave catastral.</h1>
    </div>
    <div *ngIf="adeudo.Total == 0 && this.adeudo.Cve_Catastral != null" class="container d-none d-sm-block" style="margin-top: 1rem;">
        <h1 class="text-center p-5">La clave catastral no presenta adeudo.</h1>
    </div>
    <div *ngIf="recalculando" class="container">
        <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
        <p class="text-right">Recalculando Información...</p>
    </div>
    <div *ngIf="adeudo.Total != 0" class="container">
        <div class="row justify-content-end">
            <div class="col-md-5 p-2">
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <th class="text-right">
                                <label for="">Honorarios de Notificación</label>
                            </th>
                            <td class="text-right">
                                {{this.adeudo.Honorarios_Notificacion | currency}}
                            </td>
                        </tr>
                        <tr>
                            <th class="text-right">
                                <label for="">Redondeo</label>
                            </th>
                            <td class="text-right">
                                {{this.adeudo.Redondeo | currency}}
                            </td>
                        </tr>
                        <tr>
                            <th class="text-right">
                                <label for="">Total</label>
                            </th>
                            <td class="text-right">
                                {{this.adeudo.Total | currency}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="container pb-5 pt-4">
        <div class="row justify-content-center">
            <div class="col-md-4 pb-3 pb-md-0">
                <button class="btn btn-info btn-block btn-lg btnblock" (click)="Regresar()" [disabled]="recalculando"><i class="fas fa-search mr-2"></i>Consultar Otra Clave</button>
            </div>
            <div class="col-md-4 pb-3 pb-md-0">
                <!-- <button class="btn btn-info btn-block btn-lg btnblock" (click)="Preelaborado()" [disabled]="recalculando || adeudo.Total == 0"><i class="fas fa-file-invoice-dollar mr-2"></i>Pagar en Banco</button> -->
            </div>
            <div class="col-md-4">
                <form #formulario action="https://www.adquiramexico.com.mx:443/mExpress/pago/avanzado" method="POST">
                    <input type="hidden" name="importe" value="{{this.adeudo.Total}}"/>
                    <input type="hidden" name="referencia" value="{{this.referencia_pago}}"/>
                    <input type="hidden" name="urlretorno" value="{{url_api}}PagosPredial/PostAcreditacionPago"/>
                    <input type="hidden" name="idexpress" value="2083"/>
                    <input type="hidden" name="financiamiento" value="0"/>
                    <input type="hidden" name="plazos" value=""/>
                    <input type="hidden" name="mediospago" value="100000"/>
                    <input type="hidden" name="signature" value="{{this.generated_signature}}"/>
                    <button (click)="ValidarSubmit()" class="btn btn-info btn-block btn-lg btnblock" [disabled]="recalculando || adeudo.Total == 0"><i class="far fa-credit-card mr-2"></i>Pagar en Linea</button>
                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!adeudo" class="container">
    <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
    </div>
    <p class="text-right">Cargando Información...</p>
</div>

<div>
    <div class="container">
        <div id="informacion">
            <div id="linea"></div>
            <h3 class="text-center p-3">Información del padron</h3>
            <div id="linea"></div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-4 mb-4 mb-md-0">
                <div class="card p-2" id="contribuyente">
                    <div class="row justify-content-center">
                        <i class="fas fa-user-circle" id="icono"></i>
                    </div>
                    <div class="row justify-content-center pb-2">
                        <h5>Padron: {{this.id_padron}}</h5>
                    </div>
                    <div class="row justify-content-center pb-2">
                        <h5>{{this.consulta_padron.razonsocial}}</h5>
                    </div>
                    <div class="row justify-content-center">
                        <p>RFC: <small>{{this.consulta_padron.rfc}}</small></p>
                    </div>
                    <div class="row justify-content-center">
                        <p>Domicilio: <small>{{this.consulta_padron.domicilio_contri}}</small></p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <ul>
                    <li>
                        Giro: <strong>{{this.consulta_padron.giro}}</strong>
                    </li>
                    <li>
                        Tipo Mercado: <strong>{{this.consulta_padron.tipomercado}} </strong>
                    </li>
                    <li>
                        Sindicatura: <strong>{{this.consulta_padron.sindicatura}}</strong>
                    </li>
                    <li>
                        Ubicación: <strong>{{this.consulta_padron.ubicacion}}</strong>
                    </li>
                    <li>
                        Colonia: <strong>{{this.consulta_padron.colonia}}</strong>
                    </li>
                </ul>
            </div>
            <div class="col-md-4">
                <ul>
                    <li>
                        Población: <strong>{{this.consulta_padron.poblacion}}</strong>
                    </li>
                    <li>
                        Total Metros:<strong> {{this.consulta_padron.metros}}</strong>
                    </li>
                    <li>
                        Local: <strong>{{this.consulta_padron.num_local}}</strong>
                    </li>
                    <li>
                        Salario Min. : <strong>{{this.consulta_padron.cuota}}</strong>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container mt-4" *ngIf="adeudo != null">
        <div class="d-flex align-items-center">
            <label for="">ADEUDO DESDE AÑO: <u> {{this.anio_desde_adeudo}}</u> MES: <u> {{this.mes_desde_adeudo}}</u>
            HASTA AÑO:</label>
            <select name="anio_hasta" id="anio_hasta" [(ngModel)]="this.anio_hasta" class="form-control" (change)="Recalculo()">
                <option [value]="item" *ngFor="let item of this.datos_anio_hasta">{{item}}</option>
            </select>
            <label> MES: </label>
            <select name="mes_hasta" id="mes_hasta" [(ngModel)]="this.mes_hasta" class="form-control" (change)="Recalculo()">
                <option [value]="item" *ngFor="let item of this.datos_mes_hasta">{{item}}</option>
            </select> 
        </div>
        
    </div>
    <div class="container d-none d-sm-block" style="margin-top: 1rem;" *ngIf="adeudos_padron.length != 0">
        <table class="table">
            <thead>
                <tr class="table-success breaktr">
                    <th id="descripcion" class="text-center" scope="col">DESCRIPCIÓN</th>
                    <th id="periodo" class="text-center" scope="col">PERIODO</th>
                    <th id="importe" class="text-center" scope="col">IMPORTE</th>
                </tr>
            </thead>
            <tbody *ngFor="let adeudo of this.adeudos_padron">
                <!-- empieza ciclo -->
                <tr style="cursor: pointer;" (click)="adeudo.mostrar_detalle = !adeudo.mostrar_detalle" data-toggle="tooltip" data-placement="top"
                title="Haz click para ver el detalle del adeudo">
                    <td class="text-left" headers="descripcion"><i class="fas fa-chevron-circle-right mr-2"></i>{{ adeudo.descripcion }}</td>
                    <td class="text-center" headers="periodo">{{ adeudo.anio }}</td>
                    <td class="text-center" headers="importe">{{ adeudo.importe | currency }}</td>
                </tr>
                <tr [hidden]="!adeudo.mostrar_detalle">
                    <td colspan="4">
                        <!-- <div class="container"> -->
                            <table >
                                <thead>
                                    <tr class="table-info">
                                        <th style="width: 30%;" class="text-center p-0" scope="col">ID CUENTA</th>
                                        <th style="width: 50%;" class="text-center p-0" scope="col">DESCRIPCIÓN</th>
                                        <th style="width: 10%;" class="text-center p-0" scope="col">PERIODO</th>
                                        <th style="width: 10%;" class="text-center p-0" scope="col">IMPORTE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- segundo ciclo para llenar detalle -->
                                    <tr *ngFor="let detalle of adeudo.detalle">
                                        <td class=" text-left">{{ detalle.id_cuenta }}</td>
                                        <td class="text-left">{{ detalle.descripcion }}</td>
                                        <td class="text-center">{{ detalle.periodo }}</td>
                                        <td class="text-center">{{ detalle.importe | currency}}</td>
                                    </tr>
                                    <!-- termina ciclo detalle -->
                                </tbody>
                            </table>
                        <!-- </div> -->
                        
                    </td>
                </tr>
                <!-- termina ciclo -->
            </tbody>
        </table>
    </div>

    <div *ngIf="this.total_adeudo == 0" class="container d-none d-sm-block" style="margin-top: 1rem;">
        <h1>El padron no presenta adeudo.</h1>
    </div>

    <div *ngIf="this.adeudo == null" class="container d-none d-sm-block" style="margin-top: 1rem;">
        <h1>El padron no existe.</h1>
    </div>

    <div *ngIf="recalculando" class="container">
        <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
        <p class="text-right">Recalculando Información...</p>
    </div>

    <div class="container" *ngIf="adeudos_padron.length != 0">
        <div class="row justify-content-end">
            <div class="col-md-5 p-2">
                <table class="table table-bordered">
                    <tbody>
                        <!-- <tr>
                            <th class="text-right">
                                <label for="">Honorarios de Notificación</label>
                            </th>
                            <td class="text-right">
                                {{this.adeudo.Honorarios_Notificacion | currency}}
                            </td>
                        </tr>
                        <tr>
                            <th class="text-right">
                                <label for="">Redondeo</label>
                            </th>
                            <td class="text-right">
                                {{this.adeudo.Redondeo | currency}}
                            </td>
                        </tr> -->
                        <tr>
                            <th class="text-right">
                                <label for="">Total</label>
                            </th>
                            <td class="text-right">
                                {{this.total_adeudo | currency}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="container pb-5 pt-4">
        <div class="row justify-content-center">
            <div class="col-md-4 pb-3 pb-md-0">
                <button class="btn btn-info btn-block btn-lg btnblock" (click)="InicioPadron()"><i
                        class="fas fa-search mr-2"></i>Consultar otro
                    Padron</button>
            </div>
            <div class="col-md-4 pb-3 pb-md-0">
                <!-- <button class="btn btn-info btn-block btn-lg btnblock" [disabled]="this.total_adeudo == 0 || this.adeudo == null"><i class="fas fa-file-invoice-dollar mr-2"></i>Pagar en Banco</button> -->
            </div>
            <div class="col-md-4">
                <form #formulario action="https://www.adquiramexico.com.mx:443/mExpress/pago/avanzado" method="POST">
                    <input type="hidden" name="importe" value="{{this.total_adeudo}}" />
                    <input type="hidden" name="referencia" value="{{this.referencia_de_pago}}" />
                    <input type="hidden" name="urlretorno" value="{{url_api}}PagosPredial/PostAcreditacionPago" />
                    <input type="hidden" name="idexpress" value="2083" />
                    <input type="hidden" name="financiamiento" value="0" />
                    <input type="hidden" name="plazos" value="" />
                    <input type="hidden" name="mediospago" value="100000" />
                    <input type="hidden" name="signature" value="{{this.generated_signature}}" />
                    <button (click)="ValidarSubmit()" class="btn btn-info btn-block btn-lg btnblock" [disabled]="this.total_adeudo == 0 || this.adeudo == null"><i class="far fa-credit-card mr-2"></i>Pagar en Linea</button>
                </form>
            </div>
        </div>
    </div>
</div>
import { PadronMercadoAdeudoDetalle } from './../../../../Entidades/Padrones/Mercados/padron-mercado-adeudo-detalle/padron-mercado-adeudo-detalle.module';
import { PadronMercadoAdeudo } from './../../../../Entidades/Padrones/Mercados/padron-mercado-adeudo/padron-mercado-adeudo.module';
import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { PadronService } from 'src/app/Sercivios/padron.service';
import { Padron } from 'src/app/Entidades/Padrones/padron/padron.module';
import CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-adeudo-padron',
  templateUrl: './adeudo-padron.component.html',
  styleUrls: ['./adeudo-padron.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdeudoPadronComponent implements OnInit {
  anio_desde_adeudo: string;
  mes_desde_adeudo: string;
  adeudo = null;
  id_padron: number;
  anio_hasta: string;
  mes_hasta: string;
  datos_anio_hasta = [];
  datos_mes_hasta = [];
  anio_actual: Date;
  referencia_de_pago = "";
  adeudos_padron: PadronMercadoAdeudo[] = [];
  total_adeudo = null;
  generated_signature = "";
  recalculando = false;
  url_api = "";

  consulta_padron: Padron = {
    colonia: "",
    cuota: 0,
    domicilio: "",
    domicilio_contri: "",
    establecimiento: "",
    giro: "",
    id_colonia: 0,
    id_contribuyente: 0,
    id_giro: 0,
    id_padron: 0,
    id_poblacion: 0,
    id_sindicatura: 0,
    id_tipomerado: 0,
    metros: 0,
    num_local: "",
    poblacion: 0,
    razonsocial: "",
    rfc: "",
    sindicatura: "",
    tipomercado: "",
    ubicacion: "",
    ultimo_periodo_pago: "",
  }

  mostrar_detalle = false;

  @ViewChild('formulario') formulario;

  constructor(private router: Router, private route: ActivatedRoute, private padron_service: PadronService) { }

  ngOnInit(): void {
    this.url_api = environment.url_webapi;
    this.route.params.subscribe(params => {
      this.id_padron = params.id_padron
      this.padron_service.GetPadron("6", params.id_padron).toPromise().then(data => {
        if (data.length != 0) {
          this.adeudo = data[0];
          
          this.consulta_padron.colonia = this.adeudo.colonia;
          this.consulta_padron.cuota = this.adeudo.cuota;
          this.consulta_padron.domicilio = this.adeudo.domicilio;
          this.consulta_padron.domicilio_contri = this.adeudo.domicilio_contri;
          this.consulta_padron.establecimiento = this.adeudo.establecimiento;
          this.consulta_padron.giro = this.adeudo.giro;
          this.consulta_padron.tipomercado = this.adeudo.tipomercado;
          this.consulta_padron.sindicatura = this.adeudo.sindicatura;
          this.consulta_padron.poblacion = this.adeudo.poblacion;
          this.consulta_padron.num_local = this.adeudo.num_local;
          this.consulta_padron.establecimiento = this.adeudo.establecimiento;
          this.consulta_padron.rfc = this.adeudo.rfc;
          this.consulta_padron.razonsocial = this.adeudo.razonsocial
          this.consulta_padron.ultimo_periodo_pago = this.adeudo.ultimo_periodo_pago;
          this.anio_desde_adeudo = this.consulta_padron.ultimo_periodo_pago.slice(0, 4);
          this.mes_desde_adeudo = this.consulta_padron.ultimo_periodo_pago.slice(4, 6);

          this.anio_actual = new Date();
          if (this.mes_desde_adeudo == "12") {
            this.anio_desde_adeudo = (+this.anio_desde_adeudo + 1).toString();
            this.mes_desde_adeudo = "01";
          }

          for (var i = +this.anio_desde_adeudo; i <= +this.anio_actual.getFullYear(); i++) {
            this.datos_anio_hasta.push(i);
          }

          for (var i = 1; i <= 12; i++) {
            this.datos_mes_hasta.push(i);
          }

          this.anio_hasta = this.anio_actual.getFullYear().toString();
          this.mes_hasta = (this.anio_actual.getMonth() + 1).toString();

          this.padron_service.GetPadronDetalle("6", this.id_padron, this.anio_desde_adeudo, this.mes_desde_adeudo, this.anio_hasta, this.mes_hasta).subscribe(data => {
            for (var i = 0; i < data.Table.length; i++) {
              var adeudo_padron: PadronMercadoAdeudo = new PadronMercadoAdeudo();
              adeudo_padron.anio = data.Table[i].año;
              adeudo_padron.descripcion = data.Table[i].descripcion;
              adeudo_padron.importe = data.Table[i].importe;
              adeudo_padron.mostrar_detalle = false;

              adeudo_padron.detalle = [];


              var arr_detalle = data.Table1.filter(detalle => detalle.año == adeudo_padron.anio);
              for (var j = 0; j < arr_detalle.length; j++) {
                var adeudo_padron_detalle: PadronMercadoAdeudoDetalle = new PadronMercadoAdeudoDetalle();
                adeudo_padron_detalle.id_cuenta = arr_detalle[j].id_cuenta;
                adeudo_padron_detalle.descripcion = arr_detalle[j].descripcion;
                adeudo_padron_detalle.periodo = arr_detalle[j].año_mes;
                adeudo_padron_detalle.importe = arr_detalle[j].importe;
                adeudo_padron.detalle.push(adeudo_padron_detalle);
              }

              this.adeudos_padron.push(adeudo_padron);
            }

            for (var importe of this.adeudos_padron) {
              this.total_adeudo += importe.importe;
            }

            this.referencia_de_pago = "006" + ("00000" + this.id_padron).slice(-5) + this.anio_desde_adeudo + this.mes_desde_adeudo + this.anio_hasta + ("00" + this.mes_hasta).slice(-2);
            this.generated_signature = CryptoJS.HmacSHA256(this.referencia_de_pago + this.total_adeudo + "2083", "y-7p53s-wuVeZY1u-3II").toString(CryptoJS.enc.Hex);
          });
        }
      });
    });
  }

  InicioPadron() {
    this.router.navigate(["/ConsultaAdeudoPadron"])
  }

  Recalculo() {
    this.recalculando = true;
    this.adeudos_padron = [];
    this.total_adeudo = null;
    this.padron_service.GetPadronDetalle("6", this.id_padron, this.anio_desde_adeudo, this.mes_desde_adeudo, this.anio_hasta, this.mes_hasta).subscribe(data => {
      for (var i = 0; i < data.Table.length; i++) {
        var adeudo_padron: PadronMercadoAdeudo = new PadronMercadoAdeudo();
        adeudo_padron.anio = data.Table[i].año;
        adeudo_padron.descripcion = data.Table[i].descripcion;
        adeudo_padron.importe = data.Table[i].importe;
        adeudo_padron.mostrar_detalle = false;

        adeudo_padron.detalle = [];

        var arr_detalle = data.Table1.filter(detalle => detalle.año == adeudo_padron.anio);
        for (var j = 0; j < arr_detalle.length; j++) {
          var adeudo_padron_detalle: PadronMercadoAdeudoDetalle = new PadronMercadoAdeudoDetalle();
          adeudo_padron_detalle.id_cuenta = arr_detalle[j].id_cuenta;
          adeudo_padron_detalle.descripcion = arr_detalle[j].descripcion;
          adeudo_padron_detalle.periodo = arr_detalle[j].año_mes;
          adeudo_padron_detalle.importe = arr_detalle[j].importe;
          adeudo_padron.detalle.push(adeudo_padron_detalle);
        }

        this.adeudos_padron.push(adeudo_padron);
      }

      for (var importe of this.adeudos_padron) {
        this.total_adeudo += importe.importe;
      }

      this.referencia_de_pago = "006" + ("00000" + this.id_padron).slice(-5) + this.anio_desde_adeudo + this.mes_desde_adeudo + this.anio_hasta + ("00" + this.mes_hasta).slice(-2);
      this.generated_signature = CryptoJS.HmacSHA256(this.referencia_de_pago + this.total_adeudo + "2083", "y-7p53s-wuVeZY1u-3II").toString(CryptoJS.enc.Hex);
      this.recalculando = false;
    });
  }

  ValidarSubmit() {
    // // var x = 1;
    // var anio_hasta;
    // var trimestre_hasta;
    // for (var item of this.adeudo.Detalle) {
    //   if (item.check) {
    //     anio_hasta = item.anio
    //     trimestre_hasta = item.trimestre
    //   }
    // }

    // var pre_pago: PrePagoenlinea = {
    //   id: null,
    //   referencia: this.referencia_pago,
    //   clave_catastral: this.adeudo.Cve_Catastral,
    //   desde: this.adeudo.Detalle[0].anio + " - " + this.adeudo.Detalle[0].trimestre,
    //   hasta: anio_hasta + " - " + trimestre_hasta,
    //   importe: this.adeudo.Total,
    //   redondeo: this.adeudo.Redondeo
    // }

    // this.predialurbanoservice.PrePagoEnLinea(pre_pago).subscribe(data => {
    //   this.formulario.nativeElement.submit()
    // }, (error: HttpErrorResponse) => {
    //   alert(error.error)
    // });
    this.formulario.nativeElement.submit();
  }
}
<div class="container justify-content-center mb-4">
    <div class="card col-12 justify-content-center">
        <div>
            <div class="row align-items-center justify-content-center pt-5">
                <div class="col-md-8 col-8 text-center">
                    <div class="text-center">
                        <h3 style="font-family: sans-serif;">TESORERIA MUNICIPAL</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-5">
            <div class="row align-items-center">
                <div class="col-12 text-center">
                    <div class="text-center">
                        <h3 style="font-family: sans-serif;">SU PAGO HA SIDO ACREDITADO</h3>
                        <h5 class="pt-4" style="font-family: sans-serif;">Gracias por utilizar los servicios
                            electrónicos de nuestro portal</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-5">
            <div class="row align-items-center">
                <div class="col-12 text-center">
                    <div class="text-center">
                        <strong>
                            <div class="col-12 text-center">
                                <label style="font-family: sans-serif;">No. de folio generado: {{ no_autorizacion
                                    }}</label>
                            </div>
                            <div class="col-12 text-center">
                                <label style="font-family: sans-serif;"> Referencia de pago:
                                    {{this.referencia_pago}}</label>
                            </div>

                            <div class="col-12 text-center">
                                <label class="d-flex align-items-center justify-content-center"
                                    style="font-family: sans-serif;">Para descargar su recibo de pago, haga click<a
                                        class="nav-link"
                                        href="{{url_rpt}}Ingresos/GetReciboPredial?recibo={{this.folio_recibo}}"
                                        target="_blank">aquí</a></label>
                            </div>
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container pb-5 pt-4">
        <div class="row justify-content-center">
            <div class="col-md-4 pb-3 pb-md-0">
                <button class="btn btn-info btn-block btn-lg btnblock"><i
                        class="fas fa-times mr-2"></i>FINALIZAR</button>
            </div>
            <div class="col-md-4">
                <button class="btn btn-info btn-block btn-lg btnblock" (click)="Regresar()"><i
                        class="fas fa-search mr-2"></i>CONSULTAR OTRA CLAVE</button>
            </div>
        </div>
    </div>
</div>